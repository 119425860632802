div.accordion {

    background-color: white;

}

.accordion_header {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #e6e6e6;
    transition: background-color 0.3s ease;

}

.accordion_title {
    color: #0051b5;
    font-weight: 400;
    font-style: italic;
    font-size: 16px;
}

.accordion_content {
    color: #393e46;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}


