body {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    background-color: #f9f9f9;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;


}

.form_auth {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    background-color: #f0f0f0;
    box-shadow: 8px 8px 16px #cbced1, -8px -8px 16px #ffffff;
    border-radius: 20px;
    padding: 20px;
    min-width: 350px;
    max-width: 800px;

}

.label_auth {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.input_auth {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #e1e1e1;
    box-shadow: inset 4px 4px 6px #cbced1, inset -4px -4px 6px #ffffff;
    font-size: 16px;
    margin-bottom: 10px;
}

.input_auth::placeholder {
    color: rgb(68, 68, 68);
    font-size: 14px;
    font-style: italic;
    text-align: left;
}

.button_auth {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 4px 4px 6px #cbced1, -4px -4px 6px #ffffff;
    transition: 0.1s;
}

.button_auth:hover {
    background-color: #3e8e41;
}
