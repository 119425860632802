* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


#root {
    display: flex;
    justify-content: center;
}

.App {
    width: 100%;

}

.ul__flex-authenicate {
    display: flex;
    align-items: center;
}

.ulFlex {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

html, body {
    height: 100%;
    margin: 0;
    background-repeat: no-repeat;
    background-size: 100%;
}

.content {
    min-height: 100vh;
    margin-bottom: -100px;
    padding-bottom: 100px;
}

.footer {
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 25px;
}

.footer__section {
    text-align: center;
    padding: 10px;
}

.footer__section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.footer__section-text {
    font-size: 14px;
    color: #666;
}

.footer__link {
    color: #666;
    text-decoration: none;
}

.footer__link:hover {
    text-decoration: underline;
}

.post {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    font-weight: 200;
    font-style: normal;
    padding: 15px;
    border: 2px solid rgb(68, 68, 68);
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f3f3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.5);
    border-radius: 10px;
}

.burgerMenu {
    display: none; /* Скрываем бургер-меню по умолчанию */
    cursor: pointer;
}

.burgerIcon {
    width: 30px; /* Пример размера иконки бургера */
    height: 4px; /* Пример высоты полосы иконки бургера */
    /* Пример цвета полос иконки бургера */
    margin: 6px 0;
    transition: 0.4s; /* Пример времени анимации */
}

/* Стили для адаптации меню при ширине экрана менее 867 пикселей */
@media (max-width: 897px) {
    .footer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-around;
    }

    .ulFlex {
        display: none;
        flex-direction: column;
        justify-content: space-evenly;
        top: 0;
        right: 0;
        z-index: 20;
        bottom: 0;
        position: fixed;
        width: 100%;
        background-color: #e6e6e6; /* Пример цвета фона бургер-меню */

    }


    .ulFlex.openMenu {
        display: flex; /* Показываем меню, когда оно открыто */
        text-align: center;
    }

    .burgerMenu {
        display: block;
        z-index: 50; /* Показываем бургер-меню при адаптации */
    }
}