@keyframes gradientAnimation {
    0% {
        background-position: -400% 50%;
    }
    10% {
        background-position: -300% 50%;
    }
    20% {
        background-position: -200% 50%;
    }
    30% {
        background-position: -100% 50%;
    }
    40% {
        background-position: 0 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    60% {
        background-position: 200% 50%;
    }
    70% {
        background-position: 300% 50%;
    }
    80% {
        background-position: 400% 50%;
    }
    100% {
        background-position: 500% 50%;
    }
}


.headerLogo {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    color: transparent;
    background: linear-gradient(to right, #2a2073, #00956f, #2a2073);
    -webkit-background-clip: text;
    background-clip: text;
    animation: gradientAnimation 40s linear infinite;
    -webkit-animation: gradientAnimation 40s linear infinite;
    -moz-animation: gradientAnimation 40s linear infinite;
    background-size: 400% auto;
    cursor: default;
}

.imgLogo {
    width: auto;
    height: 32px;
    margin-right: 15px;

}

.headerPos {
    display: flex;
    align-items: center;
}