.container {
    display: flex;
    justify-content: center;

}

.column_one {
    flex: 0.7;
    padding: 10px;
    margin: 10px;
}

.column_two {
    flex: 1;

    padding: 10px;
    margin: 10px;
}

.column_three {
    flex: 1;
    padding: 10px;
    margin: 10px;
}

@media screen and (max-width: 897px) {
    .column_one {
        display: none !important;
    }

    .column_three {
        display: none !important;
    }
}