div.post {
    background-color: #f0f0f0;

    margin-bottom: 2px;
    transition: 0.1s;

}

div.post_expanded {
    background-color: white;
    padding: 0 1.3cm 0 1.3cm;
    text-align: justify;
}
.accordion_header {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: white;
    padding: 1vh;
    width: 210mm;

}

div.accordion_header_expanded {
    background: white;
}

.post__title {
    flex-grow: 1;
    margin: 0;
    color: #393e46;
    font-weight: 500;
    font-size: 13px;
}

.post__title:hover {


}

div.post__title_expanded {
    font-family: 'Times New Roman', Times, serif;
    font-style: normal;
    color: black;
    font-weight: bold;

    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    text-decoration: none;
}

div.post__title_expanded:hover {
    text-decoration: none;
}


.post__content {
    font-size: 15px;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    color: black;
    line-height: 4.25mm;
    width: 100%;
    letter-spacing: 0.05em;
}

.post__annotation,
.post__keywords {
    color: #393e46;
    font-style: italic;
    font-size: 14px;
    letter-spacing: normal;
}

.post__annotation b,
.post__keywords b {
    font-weight: 600;
    font-style: italic;
}

.post__author p,
.post__author a {
    margin: 0;
    color: #393e46;
    text-align: left;
}

.post__author p:last-child {
    margin-bottom: 16px;
}

.post__author a {
    color: #0051b5;
    text-decoration: none;
}

.post__author a:hover {
    text-decoration: underline;
}

.blue_expanded {
    display: none;
}

.text_under:hover {
    text-decoration: underline;
}

.text_under_expanded:hover {
    text-decoration: none;
}

@media screen and (max-width: 768px)  {
    div.post_expanded {
        padding: 0 10px;
    }
}