.blockText_docs {

    padding: 5px;
    border: none;
    background-color: #f0f0f0;
    box-shadow: 8px 8px 16px #cbced1, -8px -8px 16px #ffffff;
    border-radius: 10px;
    margin: 25px auto;
    font-style: normal;
}

.text_docs {
    font-weight: 500;
    font-size: 16px;
    color: #393e46;
    box-shadow: inset 4px 4px 6px #cbced1, inset -4px -4px 6px #ffffff;
    padding: 40px;
    border-radius: 10px;
    letter-spacing: 1px;
}

.ul_docs {
    box-shadow: inset 4px 4px 6px #cbced1, inset -4px -4px 6px #ffffff;
    margin: 5px;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 20px;

}

.li_docs {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin: 10px;


}

.h2_docs {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background-color: #e6e6e6;
    border-radius: 10px;
    padding: 10px;

}

@media screen and (max-width: 897px) {
    .blockText_docs {
        width: 100% !important;
    }
}

@media screen and (max-width: 768px) {
    .blockText_docs {
        width: 100% !important;
    }

    .text_docs {
        padding: 20px;
    }
}



